import {
  NgModule, APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA, Injector
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpTokenInterceptorService } from './http-token.interceptor.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UsersComponent } from './users/users.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { MessagesComponent } from './messages/messages.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserFormComponent } from './user-form/user-form.component';
import { AgGridModule } from 'ag-grid-angular';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';

import {
  OktaAuthGuard,
  OktaAuthModule,
  OKTA_CONFIG,
} from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { SettingsConfig } from 'src/app/.settings.config';
import { Router } from '@angular/router';
import { AuthService } from './auth.service.service';
import { LoginComponent } from './login/login.component';
import { AgGridLinkCellComponent } from './ag-grid-link-cell/ag-grid-link-cell.component';

@NgModule({
  declarations: [
    AppComponent,
    UsersComponent,
    UserDetailComponent,
    MessagesComponent,
    DashboardComponent,
    UserFormComponent,
    FooterComponent,
    HeaderComponent,
    LoginComponent,
    AgGridLinkCellComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    AgGridModule,
    // AgGridLinkCellComponent,
    OktaAuthModule
  ],
  providers: [
    AuthService,
    OktaAuthGuard,
    OktaAuthModule,
    SettingsConfig,
    [
      {
        provide: OKTA_CONFIG,
        //useValue: { oktaAuth }
        useFactory: (settings: SettingsConfig) => {
          const oktaAuth = new OktaAuth(settings.oktaAuthOptions);
          
          // Certain features such as token auto renew, token auto remove and
          // cross-tab synchronization require OktaAuth to be running as a
          // service.
          //
          // See https://github.com/okta/okta-auth-js#running-as-a-service
          console.log('Step 1a:');
          oktaAuth.start();

          return {
            oktaAuth,
            onAuthRequired: (oktaAuthS: OktaAuth, injector: Injector) => {
              if (!oktaAuthS.authStateManager.getPreviousAuthState() ? oktaAuthS.isAuthenticated : false) {
                // App initialization stage
                console.log('Step 2a:');
                const router = injector.get(Router);
                router.navigate(['/login']);
              }
              else {
                console.log('Step 3a:');
                oktaAuthS.signOut();
              }
            },
            onAuthResume: (oktaAuthS: OktaAuth, injector: Injector) => {
              console.log('Step 4a:');
              // Use injector to access any service available within your application
              const router = injector.get(Router);
              // Redirect the user to custom login page which renders the Okta SignIn Widget
              router.navigate(['/login']);
            }
          };
        },
        deps: [SettingsConfig],
      },
      { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptorService, multi: true }
    ]
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
