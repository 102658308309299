import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import OktaAuth from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
import { AuthService } from  '../auth.service.service';
import { SettingsConfig } from '../.settings.config';



@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	public loading = true;
	public isAuthenticated = false;
	private signIn: any;
	router: any;

	constructor(
		private route: Router,
		public authSvc: AuthService,
		private settings: SettingsConfig,
		@Inject(OKTA_AUTH) public oktaAuth : OktaAuth) {
		this.loading = true;
	}
	async ngOnInit() {
		this.loading = true;
		console.log('Step 1b:');
		if (this.oktaAuth) {
			console.log('Step 2b:');
			this.isAuthenticated = await this.oktaAuth.isAuthenticated();
			console.log('Step 3b:');
			if (this.isAuthenticated) {
				console.log('Step 4b:');
				this.router.navigate(['/users']);
			} else {
				console.log('Step 5b:');
				this.oktaAuth.signInWithRedirect(this.settings.oktaAuthOptions);
			}
		}
	}

	getAPIUser() {
	  console.log('Step 6b:');
	  this.route.navigate(['/users']);
	}
}
