<div class="container">
    <div [hidden]="submitted">
      <h1>Invite New User</h1>
      <p>
        You can invite a user under any or multiple organization for which you are authorized to manage the users. You can decide whether the user can access only the requests for confidentiality that they they submitted or all requests submitted by others under an organization. You can also decide whether the user is a regular user -- Submitting Entity User (SE User) or a Submitting Entity Administrator (SE Admin) like yourself, able to manage other users and access all requests for confidentiality submitted by anyone for their organization.
      </p>
      <form (ngSubmit)="onSubmit()" #userForm="ngForm">
        <div class="form-group">
          <label for="first_name">First Name</label>
          <input type="text" class="form-control" id="first_name"
                 required
                 [(ngModel)]="user.first_name" name="first_name"
                 #first_name="ngModel">
          <div *ngIf="!first_name.valid && first_name.touched"
               class="alert alert-danger">
            First Name is required
          </div>
        </div>
        <div class="form-group">
          <label for="last_name">Last Name</label>
          <input type="text" class="form-control" id="last_name"
                 required
                 [(ngModel)]="user.last_name" name="last_name"
                 #last_name="ngModel">
          <div *ngIf="!last_name.valid && last_name.touched"
               class="alert alert-danger">
            Last Name is required
          </div>
        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input type="text" class="form-control" id="email"
                 required pattern="^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$"
                 [(ngModel)]="user.email" name="email"
                 #email="ngModel">
          <div *ngIf="!email.valid && email.touched"
               class="alert alert-danger">
           Valid Email is required
          </div>
        </div>
        <div class="form-group">
          <label for="reenter_email">Reenter Email</label>
          <input type="text" class="form-control" id="reenter_email"
                 required pattern="^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$"
                 [(ngModel)]="user.reenter_email" name="reenter_email"
                 #reenter_email="ngModel">
          <div *ngIf="user.email != user.reenter_email && reenter_email.touched && email.touched"
               class="alert alert-danger">
            Email needs to match
          </div>
          <div *ngIf="!reenter_email.valid && reenter_email.touched"
               class="alert alert-danger">
          Valid Email is required
          </div>
        </div>

        <div style="height: 100%"
          *ngIf="user.ManufacturerAssignments">
          <ag-grid-angular class="ag-theme-balham fill-height"
            [rowData]="user.ManufacturerAssignments"
            [columnDefs]="columnDefs"
            [gridOptions]="gridOptions"
            >
          </ag-grid-angular>
        </div>
        
        <button type="submit" class="usa-button usa-button--primary" [disabled]="!userForm.form.valid || getActiveSubmissionEntity(user.ManufacturerAssignments || []).length === 0 || user.email !== user.reenter_email">Submit</button>
        <!-- <button type="submit" class="btn btn-success" [disabled]="!userForm.form.valid || getActiveSubmissionEntity(user.ManufacturerAssignments || []).length === 0">Submit</button> -->
        <!-- <button type="button" class="btn btn-default" (click)="newUser(); userForm.reset()">Reset</button> -->
        <button type="button" class="usa-button usa-button--primary" (click)="newUser(); userForm.reset()">Reset</button>
      </form>
    </div>

    <div [hidden]="!submitted">
      <h2>Invitation is sent to user!</h2>
      <div class="row">
        <div class="col-xs-3">First Name</div>
        <div class="col-xs-9">{{ user.first_name }}</div>
      </div>
      <div class="row">
        <div class="col-xs-3">Last Name</div>
        <div class="col-xs-9">{{ user.last_name }}</div>
      </div>
      <div class="row">
        <div class="col-xs-3">Email</div>
        <div class="col-xs-9">{{ user.email }}</div>
      </div>

      <div *ngIf="user" style="width: 100%; ">
        <div style="height: 100%"
          *ngIf="getActiveSubmissionEntity(user.ManufacturerAssignments || []).length > 0">
          <ag-grid-angular class="ag-theme-balham fill-height"
            [rowData]="getActiveSubmissionEntity(user.ManufacturerAssignments || [])"
            [columnDefs]="columnDefs"
            [gridOptions]="gridOptions"
            >
          </ag-grid-angular>
        </div>
        <div *ngIf="getActiveSubmissionEntity(user.ManufacturerAssignments || []).length === 0" class="row">
            <span><br><br>
              <b>Submitting Entities are not selected for this user!. User will not be visible.</b>
            </span>
        </div>
      </div>
    
    </div>
  </div>