<h2>Manage Users Who Have Access to Your Company's Requests for Confidential Treatment</h2>
<!-- <ul class="users">
  <li *ngFor="let user of users">
    <a routerLink="/detail/{{user.user_id}}">
      <span class="badge">{{user.user_id}}</span>
      <span class="name">{{user.first_name}}</span>
      <span class="name">&nbsp;</span>
      <span class="name">{{user.last_name}}</span>
    </a>
    <button type="button" class="delete" title="delete user"
    (click)="delete(user)">Delete</button>
  </li>
</ul> -->

<div style="height: 100%; width: 70%"
          *ngIf="users">
          <ag-grid-angular class="ag-theme-balham fill-height"
            [rowData]="users"
            [columnDefs]="columnDefs"
            [gridOptions]="gridOptions"
            >
          </ag-grid-angular>
</div>

