import { Component, Inject, OnInit } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from 'src/environments/environment';

@Component({
    template: `<div class="status-message">{{message}}</div>`,
    styleUrls: ['./okta-callback.component.scss']
})
export class OktaCallbackComponent implements OnInit {
    message: string = "Validating authentication status...";

    constructor(
        @Inject(OKTA_AUTH) private oktaAuth: OktaAuth
    ) { }

    async ngOnInit(): Promise<void> {
        try {
            // Parse code or tokens from the URL, store tokens in the
            // TokenManager, and redirect back to the originalUri
            await this.oktaAuth.handleLoginRedirect();
        } catch (e) {
            this.message = "Not authenticated, redirecting to NHTSA Portal...";
            document.location = environment.portal_url;
        }
    }
}