import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { AuthService } from './auth.service.service';
import { UserService } from './user.service';
import { SubheaderService } from './subheader.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	isAuthenticated: boolean = false;
	constructor(
		private route: Router,
		@Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
		public authSvc: AuthService,
		public authStateService: OktaAuthStateService,
		public userService: UserService,
		public subheader: SubheaderService
	) {
		console.log('Step 1:');
		if (this.oktaAuth) {
			console.log('Step 2:');
			this.authStateService.authState$.subscribe((state) => {
				console.log('Step 3:');
				if (state && state.isAuthenticated) {
					console.log('Step 4:');
					localStorage.setItem("token", state.accessToken ? state.accessToken.accessToken : '');
					localStorage.setItem("email", state.accessToken ? state.accessToken.claims.sub : '');
					this.logAccess(state.accessToken ? state.accessToken.claims.sub : '');
				} else {
					console.log('Step 5:');
					localStorage.removeItem("token");
					localStorage.removeItem("email");
				}
				console.log("state---", state);
			})
		}
	}
	// async ngOnInit() {
	// 	this.isAuthenticated = await this.oktaAuth.isAuthenticated();
	// 	if (this.isAuthenticated) {
	// 		this.oktaAuth.getUser().then(oktaUser => {
	// 			if (oktaUser) {
	// 				this.authSvc.setOktaUserEmail(oktaUser.email || '');
	// 				localStorage.setItem('email', this.authSvc.oktaUserEmail || '');
	// 				// console.log('email',this.authSvc);
					
	// 				// console.log('logging user access');
	// 				this.logAccess(this.authSvc.oktaUserEmail || '');
	// 			}
	// 		});
	// 		const token = await this.oktaAuth.getAccessToken() || '';
	// 		localStorage.setItem('token', token);
	// 		await this.getAPIUser();

	// 	} else {
	// 		console.log('User is not authenciated');
	// 		this.authSvc.showLogin();
	// 	}
	// 	// this.setRoutingButtons();

	// }
	// async getAPIUser() {
		
	// }
	logAccess(email: string) {
		this.userService.logAccess(email).subscribe();
	}
	Logout() {
		setTimeout(() => {
			if (this.oktaAuth) {
				this.logoutApp()
				this.oktaAuth.tokenManager.clear();
			}
		  }, 500);

	}
	async logoutApp() {
		if (this.oktaAuth) {
		  // Will redirect to Okta to end the session then redirect back to the configured `postLogoutRedirectUri`
		  await this.oktaAuth.signOut();
		}
	}
}
