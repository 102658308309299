import { Component } from '@angular/core';
import { ManufacturerAssignment, User } from '../user';
import { UserService } from '../user.service';
import { ColDef, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { SubheaderService } from '../subheader.service';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent {
  gridOptions: GridOptions | undefined;

  user = new User();
  submitted = false;
  constructor(
    private userService: UserService,
    private subheader: SubheaderService
  ) { }
  onSubmit() { this.submitted = true; this.save(); }
  ngOnInit(): void {
    this.setGridOptions();
    this.newUser();
    this.subheader.setRoutingButtons();
  }
  newUser() {
    this.user = new User();
    this.user.is_active = true;
    this.userService.getSubmissionEntities()
      .subscribe(submissionEntities => this.user.ManufacturerAssignments = submissionEntities);
  }
  save(): void {
    if (this.user) {
      this.userService.updateUser(this.user)
        .subscribe();
    }
  }
  public getActiveSubmissionEntity(data: ManufacturerAssignment[]): ManufacturerAssignment[] {
    if (data) {
      return data.filter(m => m.is_active);
    } else {
      return [];
    }

  }
  public gridReady = (event: GridReadyEvent): void => {
    event.api.sizeColumnsToFit();
    event.api.setColumnDefs(this.columnDefs);
  };
  setGridOptions(): void {
    this.gridOptions = {
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: true
      },
      rowHeight: 31,
      columnDefs: this.columnDefs,
      onGridReady: (event) => this.gridReady(event),
      domLayout: 'autoHeight',
      rowSelection: 'single',
      suppressScrollOnNewData: true,
      animateRows: true,
      enableCellChangeFlash: true,
      colResizeDefault: 'shift',
      suppressClickEdit: false,
      suppressMenuHide: false,
      singleClickEdit: true
    };
  }
  columnDefs: ColDef[] = [
    {
      field: 'manufacturer_name',
      headerName: "Submitting Entity Name",
      width: 400,

    },
    {
      field: 'is_account_documents_visible',
      editable: true,
      headerName: "View all packages?",
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: [false, true],
      },
      valueFormatter: (params) => {
        return params.value ? "Yes" : "No";
      }
    },
    {
      field: 'manufacturer_user_type_id',
      headerName: "User Type",
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: this.extractValues()
      },
      valueFormatter: (params) => {
        return this.lookupValue(params.value);
      }
    },
    {
      field: 'manufacturer_id',
      hide: true
    },
    {
      field: "is_active",
      editable: true,
      headerName: "Is Active?",
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: [false, true],
      },
      valueFormatter: (params) => {
        return params.value ? "Yes" : "No";
      }
    },
  ];
  extractValues(): number[] {
    const values = this.userService.getSubmissionEntityTypes().map(m => m.id);
    return values;
  }
  lookupValue(key: number): string {
    const id = this.userService.getSubmissionEntityTypes().find(m => m.id === key)?.text;
    if (id) {
      return id;
    }
    return this.userService.getSubmissionEntityTypes().find(m => m.id === 2)?.text || "";
  }
}
