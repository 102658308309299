import { Location } from "@angular/common";
import { Injectable } from '@angular/core';
import { SigninWithRedirectOptions } from '@okta/okta-auth-js';
import { environment } from '../environments/environment';

@Injectable()
export class SettingsConfig {
  config: any;
  oktaAuthOptions: SigninWithRedirectOptions;

  constructor(private location: Location) {
    this.config = {
      endpoint: 'auth',
      configureEndpoints: ['auth', 'protected-api'],
      httpInterceptor: false,
      loginRedirect: true,
      storageChangedReload: true,    // ensure secondary tab reloading after auth status changes
      expiredRedirect: 1,            // redirect to logoutRedirect after token expiration
      profileUrl: 'oauth2/default/v1/userinfo', // specific to Okta
    };

    // Set Okta options which are the same for Internal and External apps
    this.oktaAuthOptions = {
      pkce: false,
      clientId: environment.oktaClientId,
      scopes: ['email', 'profile', 'openid'],
      issuer: environment.authAddress + 'oauth2/default',
      redirectUri: window.location.protocol + '//' + window.location.host + location.prepareExternalUrl('login/callback'),
      responseType: ['token', 'id_token'],
    };
    this.oktaAuthOptions.prompt = 'none';
  }
};
