<app-header></app-header>
<div style="margin-left: 50px; margin-right: 50px">

    <nav>
        <a *ngIf="subheader.removeInviteButton" routerLink="/dashboard" class="usa-button">Invite New User</a>
        &nbsp;&nbsp;
        <a *ngIf="!subheader.removeInviteButton" routerLink="/users" class="usa-button">Manage Users</a>
    </nav>
    <router-outlet></router-outlet>
    <br>
    <br>
    <br>

</div>
<app-footer></app-footer>
<!-- <app-messages></app-messages> -->