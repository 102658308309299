import { Component, OnInit } from '@angular/core';
import { User } from '../user';
import { UserService } from '../user.service';
import { MessageService } from '../message.service';
import { ColDef, GridOptions, GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { Router } from '@angular/router';
import { AgGridLinkCellComponent } from '../ag-grid-link-cell/ag-grid-link-cell.component';
import * as moment from 'moment';
import { SubheaderService } from '../subheader.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  users: User[] = [];
  // selectedUser?: User;
  constructor(private userService: UserService, private messageService: MessageService, private router: Router, private subheader: SubheaderService) { }
  ngOnInit(): void {
    this.setGridOptions();
    this.getUsers();
    this.subheader.setRoutingButtons();
  }
  public gridReady = (event: GridReadyEvent): void => {
    event.api.sizeColumnsToFit();
    event.api.setColumnDefs(this.columnDefs);
  };
  getUsers(): void {
    this.userService.getUsers()
      .subscribe(users => this.users = users);
  }
  // onSelect(user: User): void {
  //   this.selectedUser = user;
  //   this.messageService.add(`Selected User id=${user.user_id}`);
  // }
  delete(user: User): void {
    this.users = this.users.filter(h => h !== user);
    //this.userService.deleteUser(user.user_id).subscribe();
    this.userService.deleteUser(user.user_id || -1);
  }
  navigate(id: any): void {
    this.router.navigate([`/detail/${id}`]);
  }
  public getNullToNA(parameterpassedin: any, handleDate: boolean) {
    if (parameterpassedin) {
      if (handleDate) {
        const left10 = parameterpassedin.toString().substring(0, 10);
        return left10;
      } else {
        return parameterpassedin.toString() + " Days";
      }
      return parameterpassedin;
    } else {
      if (parameterpassedin == 0) {
        return "0 Days";
      }
      return "n/a";
    }
  }
  public getBoolToYN(parameterpassedin: any): string {
    if (!parameterpassedin) {
      return "No";
    }
    return (parameterpassedin == true ? "Yes" : "No");
  }
  public getDate(params: any): string | undefined {
    if (!params.data.update_dt) {
      return undefined;
    }
    const gmtDateTime = moment.utc(params.data.update_dt);
    return gmtDateTime.local().format('MM/DD/YYYY h:mm A');
    //moment().tz("America/New_York").format("YYYY-MM-DD HH:mm:ss.SSS"
  }
  public dateComparator(date1: string, date2: string) {

    const date1Val = moment.utc(date1, 'MM/DD/YYYY h:mm A');
    const date2Val = moment.utc(date2, 'MM/DD/YYYY h:mm A');

    if (!date1Val && !date2Val) {
      return 0;
    }
    if (!date1Val) {
      return -1;
    }
    if (!date2Val) {
      return 1;
    }
    return date1Val.diff(date2Val);
  }
  columnDefs: ColDef[] = [
    {
      field: 'first_name',
      headerName: "Name",
      width: 300,
      cellRendererFramework: AgGridLinkCellComponent,
      cellRendererParams: {
        // `text` and `link` both accept either an string expression (same as `field`) or a function that gets ICellRendererParams
        text: (params: ICellRendererParams) => `${params.value} ${params.data.last_name}`,
        link: (params: ICellRendererParams) => `/detail/${params.data.user_id}`
      },
      autoHeight: true,
      cellStyle: { 'white-space': 'pre' },
      // cellRenderer: (params: any) => {
      //   const user_id = (params.data.user_id);
      //   const last_name = (params.data.last_name);
      //   const first_name = params.value;
      //   // return `<a (click)="this.navigate(${user_id})" >${first_name} ${last_name}</a>`;
      //   //return `<a href="/detail/${user_id}" rel="noopener">${first_name} ${last_name}</a>`;
      //   //return `<a [routerLink]="/detail/${user_id}">${first_name} ${last_name}</a>`;

      // },

    },
    {
      field: 'email',
      headerName: "Email",
      width: 250,
      autoHeight: true,
      cellStyle: { 'white-space': 'pre' },


      // },

    },
    {
      field: 'last_name',
      headerName: "Last Name",
      hide: true
    },
    {
      field: 'email',
      hide: true
    },
    {
      field: 'user_id',
      hide: true
    },
    {
      field: 'glo_is_active',
      headerName: "glo Is Active?",
      width: 100,
      valueGetter: params => this.getBoolToYN(params.data.glo_is_active),
      hide: true
    },

    {
      field: 'user_id',
      headerName: "user_id",
      width: 100,
      hide: true
    },
    {
      field: 'mfr_is_active',
      headerName: "Active?",
      width: 100,
      valueGetter: params => this.getBoolToYN(params.data.mfr_is_active),
    },
    {
      field: 'marked_in_active_dt',
      headerName: "Date Deactivated",
      width: 100,
      valueGetter: params => this.getNullToNA(params.data.marked_in_active_dt, true),
      hide: false
    }, {
      field: 'marked_inactive_days_ago',
      headerName: "Days Since Deactivation",
      width: 100,
      valueGetter: params => this.getNullToNA(params.data.marked_inactive_days_ago, false),
      hide: false
    },
    /* {
      field: 'manufacturers',
      headerName: "Manufacturers",
      cellStyle: { 'white-space': 'pre' },
      minWidth: 350,
      autoHeight: true
    }, */
    // {
    //   field: 'create_by',
    //   headerName: "Invited By",
    // },
    // {
    //   field: 'create_dt',
    //   headerName: "Date Created",
    //   valueGetter: params => this.getDate(params),
    //   comparator: this.dateComparator,
    //   filter: 'agDateColumnFilter',
    // },
    // {
    //   field: 'update_by',
    //   headerName: "Last Updated By",
    // },
    // {
    //   field: 'update_dt',
    //   headerName: "Last Update Date",
    //   valueGetter: params => this.getDate(params),
    //   comparator: this.dateComparator,
    //   filter: 'agDateColumnFilter',
    // },

  ];
  gridOptions: GridOptions | undefined;
  setGridOptions(): void {
    this.gridOptions = {
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: true,
        floatingFilter: true,
      },
      rowHeight: 31,
      columnDefs: this.columnDefs,
      onGridReady: (event) => this.gridReady(event),
      domLayout: 'autoHeight',
      rowSelection: 'single',
      suppressScrollOnNewData: true,
      animateRows: true,
      enableCellChangeFlash: true,
      colResizeDefault: 'shift',
      suppressClickEdit: false,
      suppressMenuHide: false
    };
  }
}

