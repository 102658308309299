export const environment = {
    production: true,
    stage: 'dev',
    name: 'Stage Environment',
    debug: true,
    testing: true,
    authAddress: 'https://nhtsa.okta.com/',
    oktaLoginAdfs: '0oa3axs8vtrSzvFP9297',
    oktaClientId: '0oalooepf79hp2d8T297',
    // apiUrl: 'https://9c328pkfwg.execute-api.us-east-1.amazonaws.com/Prod/',
    apiUrl: 'https://cbiuserapi-stage.nhtsa.dot.gov/',
    // redirectUri: 'http://localhost:8080/login/callback',
    // postLogoutRedirectUri: 'http://localhost:8080',
    redirectUri: 'https://cbiuser-stage.nhtsa.dot.gov/login/callback',
    postLogoutRedirectUri: 'https://cbiuser-stage.nhtsa.dot.gov/',
    requireAuthentication: true,
    pkce: false,
    application_id: 'ad0ce569-11fc-457b-b0cb-d770e2b18701',
    portal_url: 'https://stage-portal.nhtsa.gov',
    tokenApi: "e29db2fb-5523-48d9-9892-01486a00177c"
  };