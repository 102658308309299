import { Inject, Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";

import { from as fromPromise, Observable, throwError, lastValueFrom, from } from "rxjs";

import { map, catchError, tap, switchMap } from "rxjs/operators";
import { Router } from "@angular/router";
import { OktaAuthStateService, OKTA_AUTH } from "@okta/okta-angular";
import { OktaAuth } from '@okta/okta-auth-js';
import { AuthService } from "./auth.service.service";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class HttpTokenInterceptorService implements HttpInterceptor {

  constructor(
    // @Inject('OKTA_AUTH') private oktaAuthSvc: OktaAuth,
    // private auth: AuthService,
    public authStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private router: Router) {

  }
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // let promises = from(
    //   Promise.all([
    //     localStorage.getItem('token'),
    //     localStorage.getItem('email')
    //   ])
    // );


    let promises = from(
      Promise.all([
        this.oktaAuth.getAccessToken(),
        this.oktaAuth.getUser()
      ])
    );


    // console.log('email = ', localStorage.getItem('email'));
    // console.log('token = ', localStorage.getItem('token'));
    // const promises = from(
    //     Promise.all([
    //       this.oktaAuth.getAccessToken(),
    //       this.oktaAuth.getUser()
    //     ])
    // );

    // Attaching User Email and Token to Request Header
    return promises.pipe(
      switchMap(res => {
        console.log(res);
        let userEmail = res[1].email || '';
        // userEmail = 'fordadmin@ford.com';
        let params = req.params
          .set('email', userEmail.toString())
          .set('logged_in_email', userEmail.toString());
        let headers = req.headers;
        headers = headers.append("Authorization", `Bearer ${res[0]}`);
        headers = headers.append("User-Email", userEmail.toString());
        headers = headers.append("User-Type", "external");
        const requestClone = req.clone({
          headers,
          params: params
        });
        return next.handle(requestClone).pipe(tap(
          (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
            }
          },
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              console.log(err);
            }
          }
        ));
      })
    );

  }

  // intercept(
  //   req: HttpRequest<any>,
  //   next: HttpHandler
  // ): Observable<HttpEvent<any> > {
  //   return fromPromise(this.handleAccess(req, next));
  // }

  private handleInterceptorErr(err: any) {
    if (err) {
      switch (err.status) {
        case 200:
          this.handle200Error(err);
          break;
        case 400:
          this.handle400Error(err);
          break;
        case 401:
          this.handle401Error(err);
          break;
        case 500:
          this.handle500Error(err);
          break;
        default:
          this.handleUnexpectedError(err);
          break;
      }
    }
  }

  handle200Error(error: any) {
    if (error["url"].indexOf("sign") > -1) {
      this.router.navigate(["/dashboard"]);
    }
  }

  handle400Error(error: any) {
    console.log(error);
  }

  handle401Error(error: any) {
    this.router.navigate(["/login"]);
    console.log(error);
  }

  handle500Error(error: any) {
    this.router.navigate(["/error"]);
    console.log(error);
  }

  handleUnexpectedError(error: any) {
    if (error['errorCode'] && error['errorCode'] === 'E0000007')
      this.router.navigate(["/login"]);
    else
      this.router.navigate(["/error"]);
    console.log(error);
  }

}
