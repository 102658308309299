import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, lastValueFrom } from "rxjs";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from './user';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthService {
  public userTokenSubject$: BehaviorSubject<any> | undefined;
  public userEmail: string | undefined;
  private signIn: any;
  private DEFAULT_ORIGINAL_URI = window.location.origin;
  private user: User | undefined;
  user_name: any = '';
	__setContentType(headers: HttpHeaders) {
		headers = headers.append("Content-Type", "application/json");
		headers = headers.append("Accept", "application/json");
		// headers = headers.append("Authorization", "ServiceToken 471558f9-5df8-45ba-9110-bb9d4e62d99e");
		return headers;
	  }
  constructor(
    private http: HttpClient,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth
  ) {
    this.oktaAuth.authStateManager.subscribe((authState) => {
      if (authState.isAuthenticated) {
        if (this.user) {
          let user_name: any = 'N/A';
          if (authState.accessToken) {
            user_name = authState.accessToken.claims.email || '';
          }
          if (user_name != this.user.email) {
            this.user = undefined;
          }
        }
      }
    });
  }
  async getUser() {
		const user_name = localStorage.getItem('email');
		let requestHeaders = new HttpHeaders();
    	requestHeaders = this.__setContentType(requestHeaders);
    	const url = environment.apiUrl + "access?" + "user_name=" + user_name + "&user_token=" + environment.tokenApi;
	
		const user = await lastValueFrom(this.http.get(url, {
			headers: requestHeaders
		})) as User;

		localStorage.setItem('user_name', `${user.first_name} ${user.last_name}`);
		return user;
	}
  async showLogin() {
    // When navigating to a protected route, the route path will be saved as the `originalUri`
    // If no `originalUri` has been saved, then redirect back to the app root
    const originalUri = this.oktaAuth.getOriginalUri();
    if (!originalUri || originalUri === this.DEFAULT_ORIGINAL_URI) {
      this.oktaAuth.setOriginalUri('/');
    }

    this.oktaAuth.token.getWithRedirect()
      .then((token) => {
        console.log('token', token);
    })

  }

  setUserToken(token: string) {
    if (this.userTokenSubject$)
      this.userTokenSubject$.next(token);
  }

  get userToken(): string {
    if (this.userTokenSubject$) {
      //Access token is set by OKTA library in OktaCallbackComponent component when we login with route 'implicit/callback'
      return this.userTokenSubject$.value;
    }
    return '';
  }

  setOktaUserEmail(email: string): void {
    this.userEmail = email;
  }

  get oktaUserEmail(): string | undefined {
    return this.userEmail || '';
  }
}
