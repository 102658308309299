import { Component, Input } from '@angular/core';
import { User, ddtype } from '../user';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { UserService } from '../user.service';
import { ColDef, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { InvokeFunctionExpr } from '@angular/compiler';



@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss', '../user-form/user-form.component.scss']
})


export class UserDetailComponent {
  MANUFACTURER_USER_TYPE = {
    ADMIN: 1,
    USER: 2
  };

  user: User | undefined;
  gridOptions: GridOptions | undefined;


  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private location: Location
  ) { }

  email_preferences = ['Draft', 'Uploaded', 'Quarantined', 'Receieved', 'Incomplete',
    'Granted', 'Denied', 'Partially Denied', 'Reconsideration Uploaded',
    'Reconsideration Quarantined', 'Reconsideration Received', 'Reconsideration Accepted',
    'Reconsideration Rejected'
  ];


  submitted = false;
  SHOW_MESSAGE_FOR_IN_SECONDS = 30 * 1000; // leave 1000 for milliseconds

  columnDefs: ColDef[] = [
    {
      field: 'manufacturer_name',
      headerName: "Submitting Entity Name",
      width: 400,
    },
    {
      field: 'is_account_documents_visible',
      editable: (params) => {
        let makeEditable = true;
        if (params.data.manufacturer_user_type_id == this.MANUFACTURER_USER_TYPE.ADMIN) {
          makeEditable = false;
        } else if (params.data.original_manufacturer_user_type_id == this.MANUFACTURER_USER_TYPE.ADMIN && params.data.manufacturer_user_type_id == this.MANUFACTURER_USER_TYPE.USER) {
          makeEditable = false;
        }
        this.openHelpMessage();
        return makeEditable;
      },
      headerName: "View all packages?",
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: [false, true],
      },
      valueGetter: (params) => {
        let isChangeFromAdminToUser = params.data.original_manufacturer_user_type_id == this.MANUFACTURER_USER_TYPE.ADMIN && params.data.manufacturer_user_type_id == this.MANUFACTURER_USER_TYPE.USER;
        if (params.data.manufacturer_user_type_id == this.MANUFACTURER_USER_TYPE.ADMIN) {
          params.data.is_account_documents_visible = true;
          return true;
        } else if (isChangeFromAdminToUser) {
          params.data.is_account_documents_visible = false;
          return false;
        }
        else {
          return params.data.is_account_documents_visible;
        }
      },
      valueFormatter: (params) => {
        return params.value ? "Yes" : "No";
      }
    },
    {
      field: 'manufacturer_user_type_id',
      headerName: "User Type",
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: this.extractValues()
      },
      valueFormatter: (params) => {
        this.openHelpMessage();
        return this.lookupValue(params.value);
      }
    },
    {
      field: 'manufacturer_id',
      hide: true
    },
    {
      field: 'original_manufacturer_user_type_id',
      hide: true
    },
    {
      field: 'manufacturer_user_type_name',
      headerName: "User Type Name",
      hide: true,
    },
    {
      field: "mfr_is_active",
      editable: true,
      headerName: "Is Active",
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: [false, true],
      },
      valueFormatter: (params) => {
        this.openHelpMessage();
        return params.value ? "Yes" : "No";
      }
    }
  ];


  onSubmit() { this.submitted = true; this.save(); }
  closeInfoMessage() {
    let myULList = <HTMLElement>document.getElementById('ulHelpList');
    myULList.classList.remove("active");
  }
  ngOnInit(): void {


    this.setGridOptions();
    this.getUser();

  }

  public gridReady = (event: GridReadyEvent): void => {
    console.log("userWeGot", this.user);
    const manufacturerAssignments = this.user?.ManufacturerAssignments;
    if (manufacturerAssignments) {
      for (let i = 0; i < manufacturerAssignments?.length; i++) {
        const manufacturerAssignment = manufacturerAssignments[i];
        console.log("manufacturerAssignment", manufacturerAssignment);
        const original_manufacturer_user_type_id: any = manufacturerAssignment.manufacturer_user_type_id;
        Object.assign(manufacturerAssignment, { original_manufacturer_user_type_id: original_manufacturer_user_type_id });
        console.log("manufacturerAssignment", manufacturerAssignment);
      }
    }
    event.api.sizeColumnsToFit();
    event.api.setColumnDefs(this.columnDefs);
  };

  setGridOptions(): void {
    this.gridOptions = {
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: true
      },
      rowHeight: 31,
      columnDefs: this.columnDefs,
      onGridReady: (event) => this.gridReady(event),
      domLayout: 'autoHeight',
      rowSelection: 'single',
      suppressScrollOnNewData: true,
      animateRows: true,
      enableCellChangeFlash: true,
      colResizeDefault: 'shift',
      suppressClickEdit: false,
      suppressMenuHide: false,
      singleClickEdit: true
    };
  }

  getUser(): void {
    const user_id = Number(this.route.snapshot.paramMap.get('user_id'));
    const gotUser = this.userService.getUser(user_id);
    gotUser.subscribe(user => this.user = user);

    // this.userService.getUsers()
    // .subscribe(users => this.user =
    //   (users.find(h => h.user_id === user_id))

    // );

  }
  goBack(): void {
    this.location.back();
  }
  save(): void {
    if (this.user) {
      //this.user.is_active = true;
      this.userService.updateUser(this.user)
        .subscribe(() => this.goBack());
    }
  }
  extractValues(): number[] {
    const values = this.userService.getSubmissionEntityTypes().map(m => m.id);
    return values;
  }
  lookupValue(key: number): string {
    const id = this.userService.getSubmissionEntityTypes().find(m => m.id === key)?.text;
    if (id) {
      return id;
    }
    return this.userService.getSubmissionEntityTypes().find(m => m.id === 2)?.text || "";
  }
  openHelpMessage(): void {
    let myULList = <HTMLElement>document.getElementById('ulHelpList');

    myULList.classList.add("active");

    delayAndLog("Delay and show message", this.SHOW_MESSAGE_FOR_IN_SECONDS);
  }

}
function sleep(ms: number): Promise<void> {
  return new Promise(
    (resolve) =>
      setTimeout(resolve, ms));
}


async function delayAndLog(
  message: string, delayMs: number) {
  let sleepCounter = Number(sessionStorage.getItem("sleepCounter"));
  if (!sleepCounter) {
    sessionStorage.setItem("sleepCounter", '0');
  }
  sessionStorage.setItem("sleepCounter", (++sleepCounter).toString());
  console.log(
    `Sleeping for ${delayMs} 
    milliseconds...sleep counter is ${sleepCounter}`);
  // find out previous await sleep and cancel it

  const sleepPromise = await sleep(delayMs);
  sessionStorage.setItem("sleepCounter", (--sleepCounter).toString());
  console.log(
    `Woke up after sleeping for
    ${delayMs} milliseconds. Message: ${message}, and sleepcounter is${sleepCounter}`);
  let myULList = <HTMLElement>document.getElementById('ulHelpList');
  if (myULList && myULList.classList && sleepCounter < 1) {
    //FIXME do we want to hider after a particular time?
    myULList.classList.remove("active");
  }
}


