<a href="#main" class="skip-main-link" tabindex="1">Skip to main content</a>
<section class="usa-banner" aria-label="Official website of the United States government" tabindex="1">
  <div id="usa-banner" class="usa-accordion">
    <header class="usa-banner__header">
      <div class="usa-banner__inner">
        <div class="grid-col-auto">
          <img aria-hidden="true" class="usa-banner__header-flag" src="assets/img/us_flag_small.png" alt="" />
        </div>
        <div class="grid-col-fill tablet:grid-col-auto" aria-hidden="true">
          <p class="usa-banner__header-text">
            An official website of the United States government
          </p>
          <p class="usa-banner__header-action">Here’s how you know</p>
        </div>
        <button type="button" class="usa-accordion__button usa-banner__button" aria-expanded="false"
          aria-controls="gov-banner-default-default">
          <span class="usa-banner__button-text">Here’s how you know</span>
        </button>
        <div class="flex-fill"></div>
        <div class="flex-auto usdot-text-highlight" tabindex="1" role="region" aria-label="environmentName">
          {{environmentName}}</div>

      </div>
    </header>
    <div class="usa-banner__content usa-accordion__content" id="gov-banner-default-default">
      <div class="grid-row grid-gap-lg">
        <div class="usa-banner__guidance tablet:grid-col-6">
          <img class="usa-banner__icon usa-media-block__img" src="assets/img/icon-dot-gov.svg" role="img" alt=""
            aria-hidden="true" />
          <div class="usa-media-block__body">
            <p>
              <strong>Official websites use .gov</strong><br />A
              <strong>.gov</strong> website belongs to an official government
              organization in the United States.
            </p>
          </div>
        </div>
        <div class="usa-banner__guidance tablet:grid-col-6">
          <img class="usa-banner__icon usa-media-block__img" src="assets/img/icon-https.svg" role="img" alt=""
            aria-hidden="true" />
          <div class="usa-media-block__body">
            <p>
              <strong>Secure .gov websites use HTTPS</strong><br />A
              <strong>lock</strong> (
              <span class="icon-lock"><svg xmlns="http://www.w3.org/2000/svg" width="52" height="64" viewBox="0 0 52 64"
                  class="usa-banner__lock-image" role="img" aria-labelledby="banner-lock-description-default"
                  focusable="false">
                  <title id="banner-lock-title-default">Lock</title>
                  <desc id="banner-lock-description-default">Locked padlock icon</desc>
                  <path fill="#000000" fill-rule="evenodd"
                    d="M26 0c10.493 0 19 8.507 19 19v9h3a4 4 0 0 1 4 4v28a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V32a4 4 0 0 1 4-4h3v-9C7 8.507 15.507 0 26 0zm0 8c-5.979 0-10.843 4.77-10.996 10.712L15 19v9h22v-9c0-6.075-4.925-11-11-11z" />
                </svg> </span>) or <strong>https://</strong> means you’ve safely connected to
              the .gov website. Share sensitive information only on official,
              secure websites.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="bg-primary text-white padding-left-3">
  <div class="grid-row grid-gap padding-right-3" role="heading" aria-level="1">
    <div class="grid-col-auto usdot-primary-logo" role="region" aria-label="NHTSA logo" id="nhtsa-logo" tabindex="1"
      aria-labelledby="NHTSA Logo">
      <a href="{{portal_url}}"><img alt="NHTSA logo" aria-label="NHTSA logo" src="assets/img/u2.png" width="200px"
          height="50px" /></a>
    </div>
    <h1 class="flex-auto usdot-primary-header" tabindex="1">
      {{ title }}
    </h1>
    <div class="flex-fill"></div>
    <div class="flex-auto usdot-primary-header-userinfo" role="region" tabindex="1" aria-label="User Profile">
      <a class="grid-row flex-align-center usdot-primary-header-clickable" aria-controls="user-profile-modal"
        data-open-modal>
        <div *ngIf="isAuthenticated" class="flex-auto">
          <div role="button" aria-label="User Info Button"><b>User: </b>
            {{user_name}}
          </div>
          <div role="button" aria-label="Current User Role">
            <b>Role:</b> {{currentUserRole}}
          </div>
        </div>
        <div *ngIf="isAuthenticated" class="flex-auto">
          <span class="material-symbols-outlined usdot-primary-header-icon">account_circle</span>
        </div>
      </a>
      <div class="usa-modal" id="user-profile-modal" aria-labelledby="modal-1-heading"
        aria-describedby="modal-1-description">
        <div class="usa-modal__content">
          <div class="usa-modal__main">
            <h2 class="usa-modal__heading font-weight" id="modal-1-heading">
              User Profile
            </h2>
            <div *ngIf="isAuthenticated" class="usa-prose">
              <p *ngIf="currentUserRoleId==6" id="modal-1-description">Below is your user profile. To request changes to
                this information, contact your SE Admin if applicable or the <a
                  href="mailto:cbi-helpdesk@dot.gov">cbi-helpdesk@dot.gov</a>.</p>
              <p *ngIf="currentUserRoleId!=6" id="modal-1-description">Below is your user profile. To request changes to
                this information, please contact the <a href="mailto:cbi-helpdesk@dot.gov">cbi-helpdesk@dot.gov</a>.</p>
              <ul class="usa-list">
                <li><b>User:</b> {{user_name}}</li>
                <li><b>Email:</b> {{userEmail}}</li>
                <li><b>Role:</b> {{currentUserRole}}</li>
                <li *ngIf="userManufacturers.length > 0">
                  <b>Submitting Entity Role(s):</b>
                  <ul>
                    <li *ngFor="let manufacturer of userManufacturers">
                      {{manufacturer.manufacturer_name}} - {{manufacturer.manufacturer_user_type_name}}
                    </li>
                  </ul>
                </li>
                <li *ngIf="userProgramOffices.length > 0">
                  <b>Program Office(s):</b>
                  <ul>
                    <li *ngFor="let programOffice of userProgramOffices">
                      {{programOffice.program_office_name}}
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div class="usa-modal__footer">
              <ul class="usa-button-group">
                <li class="usa-button-group__item">
                  <button type="button" class="usa-button" data-close-modal>
                    Close
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <button type="button" class="usa-button usa-modal__close" aria-label="Close this window" data-close-modal>
            <svg class="usa-icon" aria-hidden="true" focusable="false" role="img">
              <use xlink:href="assets/img/sprite.svg#close"></use>
            </svg>
          </button>
        </div>
      </div>
      <a *ngIf="isAuthenticated" class="usdot-primary-header-clickable" role="button" (click)="logout()">Return to
        Portal<span class="material-symbols-outlined usdot-primary-header-icon">logout</span></a>
    </div>
  </div>
</div>
<br />
<div class="grid-row grid-gap">

  <p><b _ngcontent-ng-c2196020528="">As a Submitting Entity Administrator, you are
      responsible for monitoring and promptly deleting the accounts of any Submitting Entity Users, other Submitting
      Entity Administrators, or outside counsel at a law firm who no longer work for or represent your company. If
      your employment at your company ends, you are responsible for immediately notifying NHTSA and requesting that
      NHTSA deactivate your account.</b></p>

</div>