import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubheaderService {
	removeInviteButton: boolean = true;
  constructor(private route: Router) {}
  setRoutingButtons() {
		console.log('calling button setting');
		this.route.events.pipe(
			filter(event => event instanceof NavigationEnd)
		  ).subscribe((event) => {
			if (event instanceof NavigationEnd) {
				if (event.urlAfterRedirects.includes('d')) {
					this.removeInviteButton = false;
				  } else {
					this.removeInviteButton = true;
				  }
			}
		  });
	}

 
}
