<!-- <div *ngIf="user">
  <h2>{{user.first_name}} Details</h2>
  <div>id: {{user.user_id}}</div>
  <div>
    <label for="user-first-name">First Name: </label>
    <input id="user-first-name" [(ngModel)]="user.first_name" placeholder="user-first-name">
  </div>
  <div>
    <label for="user-last-name">Last Name: </label>
    <input id="user-last-name" [(ngModel)]="user.last_name" placeholder="user-last-name">
  </div>
  <button type="button" (click)="goBack()">go back</button>
  <button type="button" (click)="save()">save</button>
</div> -->
<div class="container" *ngIf="user">
  <div [hidden]="submitted">
    <h1>Edit User</h1>
    <form (ngSubmit)="onSubmit()" #userForm="ngForm">
      <div class="form-group">
        <label for="first_name">First Name</label>
        <input type="text" class="form-control" id="first_name" required readonly [(ngModel)]="user.first_name"
          name="first_name" #name="ngModel">
        <div [hidden]="name.valid || name.pristine" class="alert alert-danger">
          First Name is required
        </div>
      </div>
      <div class="form-group">
        <label for="last_name">Last Name</label>
        <input type="text" class="form-control" id="last_name" required readonly [(ngModel)]="user.last_name"
          name="last_name" #name="ngModel">
        <div [hidden]="name.valid || name.pristine" class="alert alert-danger">
          Last Name is required
        </div>
      </div>

      <div class="form-group">
        <label for="email">Email</label>
        <input type="text" class="form-control" id="email" required readonly [(ngModel)]="user.email" name="email"
          #name="ngModel">
        <div [hidden]="name.valid || name.pristine" class="alert alert-danger">
          Email is required
        </div>
      </div>

      <!-- <div class="form-group">
        <label for="is_active">Is User Active?</label>
        <select class="form-control"  id="is_active"
          required
          [(ngModel)]="user.is_active" name="is_active">
          <option value=true>Yes</option>
          <option value=false>No</option>
        </select>
      </div> -->
      <div [hidden]="submitted" *ngIf="user" style="width: 100%; ">
        <div style="height: 100%" *ngIf="user.ManufacturerAssignments">
          <ag-grid-angular class="ag-theme-balham fill-height" [rowData]="user.ManufacturerAssignments"
            [columnDefs]="columnDefs" [gridOptions]="gridOptions">
          </ag-grid-angular>
        </div>
      </div>



      <!-- <div class="form-group">
        <label for="email_preferences">Notification Preferences when package is in state of</label>
        <select multiple class="form-control"  id="email_preferences"
          required
          [(ngModel)]="user.email_preferences" name="email_preferences">
          <option *ngFor="let email_preference of email_preferences" [ngValue]="email_preference">{{email_preference}}</option>
        </select>
      </div> -->

      <button type="submit" class="usa-button" [disabled]="!userForm.form.valid">Submit</button>

    </form>
    <div class="infoWrapper" id="ulHelpList">
      <span class="btnClose" (click)="closeInfoMessage()">&#x2715;</span>
      <h4><span>&#9432;</span>Guidance for Managing Users:</h4>
      <ul>
        <li>If someone becomes a "Submitting Entity Admin," that person will be able to view all packages.</li>
        <li>If you change a "Submitting Entity Admin" to a "Submitting Entity User," the "View All Packages" column will
          initially be set to "No" because that is the default setting.</li>
        <li>If an individual is a "Submitting Entity User," then "View All Packages" can be set to either "Yes" or "No."
        </li>
        <li>If you set "View All Packages" to "Yes," this will allow a "Submitting Entity User" to view all packages
          submitted by your company (i.e., the settings are either viewing all packages or just viewing packages that an
          individual submitted).</li>
        <li>If you change a user to "Inactive," they will no longer be able to see any packages. However, you may
          subsequently reactivate a user. </li>
      </ul>
    </div>
  </div>


  <div [hidden]="!submitted">
    <h2>Change in user permissions are saved!</h2>
    <div class="row">
      <div class="col-xs-3">First Name</div>
      <div class="col-xs-9">{{ user.first_name }}</div>
    </div>
    <div class="row">
      <div class="col-xs-3">Last Name</div>
      <div class="col-xs-9">{{ user.last_name }}</div>
    </div>
    <div class="row">
      <div class="col-xs-3">Email</div>
      <div class="col-xs-9">{{ user.email }}</div>
    </div>
    <!-- <div class="row">
      <div class="col-xs-3">Is User Active?</div>
      <div class="col-xs-9">{{ user.is_active }}</div>
    </div> -->
    <div class="row">
      <div class="col-xs-3">User Assignments?</div>
    </div>
    <div class="row">
      <div *ngIf="user" style="width: 100%; ">
        <div style="height: 100%" *ngIf="user.ManufacturerAssignments">
          <ag-grid-angular class="ag-theme-balham fill-height" [rowData]="user.ManufacturerAssignments"
            [columnDefs]="columnDefs">
          </ag-grid-angular>
        </div>
      </div>
    </div>

    <br>
    <!-- <button type="button" class="btn btn-primary" (click)="submitted=false">Edit Again?</button>
    <button type="button" class="btn btn-primary" (click)="goBack()">go back</button> -->
    <p>Please wait, redirecting to manage users page ...</p>
  </div>
</div>