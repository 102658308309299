import { Component, Inject, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service.service';
import OktaAuth, { AuthState } from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
import { User } from '../user';
import USWDS from "@uswds/uswds/js";
const { accordion, modal } = USWDS;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  environmentName: string = '';
  title = 'Confidential Business Information Portal — User Management';
  portal_url = environment.portal_url;
  user: User | undefined;
  currentUserRole: string | undefined;
  currentUserRoleId: any;
  isAuthenticated: boolean = false;
  // User Information for display
  userManufacturers: any[] = [];
  userProgramOffices: any[] = [];
  ref;

  user_name = localStorage.getItem('user_name') || '';
  userEmail: any;

  constructor(private authService: AuthService, @Inject(OKTA_AUTH) private oktaAuth: OktaAuth) {
    this.ref = document.body;
  }
  async ngOnInit() {

    // Respond to changes in the authentication state of the user
    this.oktaAuth.authStateManager.subscribe(async (authState: AuthState) => {
      if (authState.isAuthenticated) {
        this.updateUserDisplayInfo(authState.isAuthenticated);
      }
    });
    
    this.environmentName = environment.name;
    accordion.on(this.ref);
    modal.on(this.ref);
  }

  ngOnDestroy() {
    accordion.off(this.ref);
    modal.off(this.ref);
  }

  async updateUserDisplayInfo(isAuthenticated: boolean) {
    if (isAuthenticated) {

      // Set user display name for header
      this.user = await this.authService.getUser();
      console.log(this.user);
      if (this.user) {
        this.user_name = this.user.first_name + ' ' + this.user.last_name;
        this.userEmail = this.user.email;
        this.currentUserRole = this.user.roles ? this.user.roles[0].role_name : '';
        this.currentUserRoleId = this.user.roles ? this.user.roles[0].role_id : '';


        // Store sorted list of user's manufacturers
        this.userManufacturers = this.sortDataBy(this.user.manufacturers, 'manufacturer_name');

        // Store sorted list of user's program offices
        this.userProgramOffices = this.sortDataBy(this.user.program_offices, 'program_office_name');

        // Prepare roles for display as combination of all role names
/*         const ROLE_ID_NCC_ADMIN = 7;
        const roleNames = await Promise.all(this.user.roles.map(async (role) => {
          let roleName = role.role_name;

          // Display "NCC Submitter" in place of "NCC Admin" role on the external application
          if (role.role_id == ROLE_ID_NCC_ADMIN) {
            roleName = "NCC Submitter";
          }

          return roleName;
        }));
        this.currentUserRole = roleNames.join(", "); */
      }

      // Enable the User Management Link on External App for Submitting Entity Admins
      const MANUFACTURER_USER_TYPE_ID_SUBMITTING_ENTITY_ADMIN = 1;

      // Set to true only after updating all the user information so that all
      // the user information appears at the same time to the user
      this.isAuthenticated = true;

    } else {
      // Set to false to hide the user information from view immediately, before
      // resetting user information
      this.isAuthenticated = false;

      // Update component state to match the authentication state
      this.user_name = '';
      this.currentUserRole = '';
    }
  }

  sortDataBy(data: any, byKey: any) {
    //here your logic code
    //let sortedData;
    if (byKey == 'label') {
      // we use javascript sort function to compare to value
      return data.sort(function (a: any, b: any) {
        // here a , b is whole object, you can access its property
        //convert both to lowercase
        let x = a.label.toLowerCase();
        let y = b.label.toLowerCase();
        if (x.startsWith('other')) {
          return 1;
        }
        if (y.startsWith('other')) {
          return -1;
        }
        //compare the word which is comes first
        if (x > y) { return 1; }
        if (x < y) { return -1; }
        return 0;
      });
    }
    if (byKey == 'manufacturer_name') {
      // we use javascript sort function to compare to value
      return data.sort(function (a: any, b: any) {
        // here a , b is whole object, you can access its property
        //convert both to lowercase
        let x = a.manufacturer_name.toLowerCase();
        let y = b.manufacturer_name.toLowerCase();
        if (x.startsWith('other')) {
          return 1;
        }
        if (y.startsWith('other')) {
          return -1;
        }
        //compare the word which is comes first
        if (x > y) { return 1; }
        if (x < y) { return -1; }
        return 0;
      });
    }
    if (byKey == 'program_office_name') {
      // we use javascript sort function to compare to value
      return data.sort(function (a: any, b: any) {
        // here a , b is whole object, you can access its property
        //convert both to lowercase
        let x = a.program_office_name.toLowerCase();
        let y = b.program_office_name.toLowerCase();
        //compare the word which is comes first
        if (x > y) { return 1; }
        if (x < y) { return -1; }
        return 0;
      });
    }
  }

  logout() {
    let text;
    if (confirm("Are you sure you want to logout?") == true) {
      //text = "You pressed OK!";
      localStorage.clear();
      window.location.href = environment.portal_url;

    } else {
      //text = "You canceled!";
    }
  }
}
