import { Injector, NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { OktaCallbackComponent } from './login/okta-callback/okta-callback.component';
import { OktaAuthGuard } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { UsersComponent } from './users/users.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { LoginComponent } from './login/login.component';
//import { OktaCallbackComponent } from '@okta/okta-angular';


export function onAuthRequired(oktaAuth: OktaAuth, injector: Injector) {
  const router = injector.get(Router);
  router.navigate(['/login']);
}


const routes: Routes = [
  { path: '', redirectTo: '/users', pathMatch: 'full', title: 'Manage Users' },
  { path: 'dashboard', component: DashboardComponent, canActivate: [OktaAuthGuard], data: { onAuthRequired }, title: 'Invite New User' },
  { path: 'login', component: LoginComponent },
  { path: 'login/callback', component: OktaCallbackComponent },
  { path: 'implicit/callback', component: OktaCallbackComponent },
  { path: 'dev/login/callback', component: OktaCallbackComponent },
  { path: 'dev/implicit/callback', component: OktaCallbackComponent },
  { path: 'detail/:user_id', component: UserDetailComponent, canActivate: [OktaAuthGuard], data: { onAuthRequired }, title: 'Edit User' },
  { path: 'users', component: UsersComponent, canActivate: [OktaAuthGuard], data: { onAuthRequired }, title: 'Manage Users' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
