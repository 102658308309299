<!-- <footer class="footer" role="contentinfo">
    <div class="line1">
      <a title="Policies" href="https://www.nhtsa.gov/about-nhtsa/web-policies-notices">Policies</a>
      <span> | </span>
      <a title="Terms of Use" href="https://www.nhtsa.gov/about-nhtsa/terms-use">Terms of Use</a>
      <span> | </span>
      <a title="Freedom of Information Act (FOIA)" href="https://www.nhtsa.gov/about-nhtsa/foia">FOIA</a>
      <span> | </span>
      <a title="Privacy Policy" href="https://www.nhtsa.gov/about-nhtsa/privacy-policy">Privacy Policy</a>
      <span> | </span>
      <a title="Accessibility" href="https://www.nhtsa.gov/about-nhtsa/accessibility">Accessibility</a>
    </div>

    <div class="line2">1200 New Jersey Avenue, SE, West Building Washington DC 20590 USA
    </div>
    <div class="line3">This application works best in recent versions of Edge, Firefox, Chrome and Safari</div>
    <div class="line4"></div>
  </footer> -->

  <div class="grid-container" style="margin-top:40px;">If you have questions or issues relating to the Confidential
    Business Information Portal, please contact the CBI Portal Help Desk at <a
      href="mailto:cbi-helpdesk@dot.gov">cbi-helpdesk@dot.gov</a></div>
  <footer class="footer bg-primary text-white" role="contentinfo">
    <div class="line1">
      <a title="Policies" href="https://www.nhtsa.gov/about-nhtsa/web-policies-notices">Policies</a>
      <span> | </span>
      <a title="Terms of Use" href="https://www.nhtsa.gov/about-nhtsa/terms-use">Terms of Use</a>
      <span> | </span>
      <a title="Freedom of Information Act (FOIA)" href="https://www.nhtsa.gov/about-nhtsa/foia">FOIA</a>
      <span> | </span>
      <a title="Privacy Policy" href="https://www.nhtsa.gov/about-nhtsa/privacy-policy">Privacy Policy</a>
      <span> | </span>
      <a title="Vulnerability Disclosure Policy"
        href="https://www.transportation.gov/vulnerability-disclosure-policy">Vulnerability Disclosure Policy</a>
      <span> | </span>
      <a title="Accessibility" href="https://www.nhtsa.gov/about-nhtsa/accessibility">Accessibility</a>
    </div>
  
    <div class="line2">1200 New Jersey Avenue, SE, Washington DC 20590 USA
    </div>
    <div class="line3">This application works best in recent versions of Edge, Firefox, Chrome and Safari.</div>
    <div class="line4"></div>
  </footer>
